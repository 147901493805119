<template>
    <div>

		<div class="title">
			<p class="main">Nuclear War by Annie Jacobsen</p>
			<div class="subheadings">
				<p class="published-date">12th March 2025</p>
				
				
			</div>
		</div>

		<div class="article">
			<p>This book annoyed me more or less right away and, while it did get more interesting, it didn’t get less annoying.</p>

			<p>I came across Nuclear War by Annie Jacobsen in the programme notes for the Steve Coogan version of Dr Strangelove towards the tail end of last year. Dr Strangelove, if you remember, is the classic story of how the entire nuclear defence establishment is built on an idea that doesn’t make any sense - deterrence. The film and play make the case that all it takes is one rogue actor and suddenly the whole thing comes tumbling down because all logic goes out the window in a game with no winners. It makes this case, very convincingly, even to people like me who are prepared to accept the horrifying reality of nuclear weapons and don’t think their abolition is realistic.</p>

			<p>Annie Jacobsen makes the case that it would be bad if no one answered their phone for a bit after a nuclear attack on America.</p>

			<p>It’s honestly a book whose premise doesn’t stand up to the slightest scrutiny. But let’s start from the beginning shall we? There are a few things here that are just annoying. First of all, I know that the term “nuclear holocaust” is just an accepted idiom now but Jacobsen goes out of her way to compare the numbers of dead in a global extinction to those killed in the Holocaust of the Second World War. The two are nothing alike and it borders on offensive to say “Yes all that deliberate targeted genocide was very unpleasant - but what if we all die?!” which somewhat misses the point in my view.</p>

			<p>Secondly, Jacobsen relies on a number of alleged quotes for which I can find no serious attribution. The best example being the well know “World War Four will be fought with sticks and stones” phrase most commonly held to have been said by apparent <a href="https://www.snopes.com/fact-check/einstein-world-war-iv-sticks-stones/">Professional Quotation Generation Machine Albert Einstein</a>.</p>

			<p>Several elements in the story simply don’t add up. Jacobsen talks repeatedly about how decisions in this scenario must be made at speed because a nuclear attack is so devastating. This is obviously true, but the scenario presented is highly survivable for the US. Even I can recognise that, awful though it would be, a single small warhead is not nuclear armageddon. Once you see that, it’s hard to take anything else in the book seriously. The whole thing hinges on the repeated line “And Moscow still isn’t picking up the phone”. Give. Me. A. Break. I think they probably would? Have you tried WhatsApp Mr President? Have you at least got two grey ticks? It’s just madness.</p>

			<p>Every decision taken makes no sense and doesn’t refute Jacobsen’s central premise which is that deterrence is a flawed concept. She proves this by… having North Korea ignore deterrence????? Because of “bunkers”????? Please Annie make it make sense. It’s not an argument to say “Yes but what if they did?!”. Having failed to establish her central point, Jacobsen takes it to absurd extremes. The US retaliates by launching 100 ICBMs from ground based silos. This is illogical. We have nuclear submarines precisely so that a threat can be countered without having to launch from home turf. Why do we do that? Because let’s say you only want to retaliate against North Korea. It would be sub-optimal in that case to fire missiles over Russia and China. After all, what if they didn’t pick up the phone? No laughing at the back.</p>

			<p>I could go on and on but I think it’s important to recognise that a lot of seriously credentialed people sat down with Jacobsen for this book. I can only imagine that she asked incredibly leading questions about very specific ridiculous scenarios and cut out the caveats given by the experts. Because there must have been caveats. I had a look at her other work having read the book and discovered she’s written some pretty well debunked stuff about Roswell and Area 51 so I think we have to put this down to “Not the book I wanted to read but probably very good for a different kind of person”. Further research reveals that Jacobsen is quite open about the fact that she is not an expert on the subjects she writes in and seeks to shock and provoke which I guess she did manage.</p>

			<p>I’m not sorry I finished Nuclear War because there is a lot of decent information in there about how these things would come to pass. It’s just that the framing provided completely takes you out of it. I was repeatedly reminded of when I lived near the Faslane Naval Base, which houses the UKs nuclear arsenal and would have people constantly tell me that if that base got bombed it’d be a nightmare. It made me want to scream “That’s not how nuclear bombs work!” slightly less than this book did. And that’s really saying something about a book that purports to be about nuclear bombs.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "NuclearWarArticle"
	}

</script>


<style>

</style>