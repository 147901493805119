<template>
    <div>

		<div class="title">
			<p class="main">Fermat's Last Theorem by Simon Singh</p>
			<div class="subheadings">
				<p class="published-date">21st February 2025</p>
				
				
			</div>
		</div>

		<div class="article">
			<p>Years ago, I watched a video starring Simon Singh, the author of Fermat’s Last Theorem, explaining the story of Andrew Wiles’ proof of this 300 year old problem. It is so full of fantastic lines and brilliant little details that I think it was the main thing that turned me back onto popular mathematics as a genre in the first place. The guy also owns an enigma machine but I think I’m the only person I know who thinks that’s cool. I’m not going to try and give a summary of the story here. Just watch <a href="https://www.youtube.com/watch?v=qiNcEguuFSA">the video</a>. But I do need to give a bit of background so you can understand why this has been one of those problems that has fascinated people for literally centuries.</p>

			<p>We’re all hopefully still familiar with the Pythagorean Theorem. If you have a right angle trial, the length of the longest side when squared is the same as the sum of the length of the other two sides when squared. x<sup>2</sup> + y<sup>2</sup> = z<sup>2</sup>. So for example, 3<sup>2</sup> + 4<sup>2</sup> = 5<sup>2</sup> which comes out to 9 + 16 = 25. Easy, right? There are loads of whole number (that is, numbers you count with) solutions to this theorem. So the year is around 1640, and Fermat, an amateur mathematician, is reading a maths textbook called Arithmetica by Diophantus. In this book, Diphantus outlines the Pythagorean Theorem and says “Okay but what about x<sup>3</sup> + y<sup>3</sup> = z<sup>3</sup>? Or x<sup>4</sup> + y<sup>4</sup> = z<sup>4</sup>? Or actually any number more than 2?”</p>

			<p>“Are there solutions to x<sup>n</sup> + y<sup>n</sup> = z<sup>n</sup> where n > 2?” And Fermat promptly responds in a liner note, “Rei demonstrationem mirabilem sane detexi. Hanc marginis exiguitas non caperet.” - I have a discovered a truly marvelous proof of this matter which this margin is too narrow to contain”. Fermat then dies without publishing his proof, if he had one.</p>

			<p>What’s brilliant about this problem is that it looks very approachable. There’s loads of solutions when n is 2, so what’s so hard about n = 3? It turns out, a lot. And the story of how this problem confused and befuddled centuries of mathematicians and how Andrew Wiles devoted the better part of his career to it is absolutely fascinating and kind of unputdownable.</p>

			<p>Yes, it does cover a lot of the same history as The Music of the Primes from last week and I would say that Fermat’s Last Theorem is definitely the more accessible book despite having a lot more Actual Maths in it. But it tells a more direct story and has more identifiable main characters. It honestly reads like it could be a film or a series or something. It’s worth saying that even when the problem is as initially unthreatening as “xn + yn = zn where n > 2” it should be clear that when the greatest mathematicians working for over three centuries can’t solve it, it probably actually is pretty threatening. The maths involved obviously gets harder and more abstract as the book goes on to the point where Singh just gives up, understandably. For reasons that become clear when Wiles is working on part of his proof towards the end of the book, one of his colleagues explains that the concepts involved are so complex and strange that it would take an actual lecture series for even professional mathematicians to understand it. In fact, that is how Wiles delivered his proof - in a series of three lectures.</p>

			<p>But this is kind of what I loved about the story. The problem initially seems like it shouldn’t be that hard, but it turned out that to solve it, you had to look at what appeared to be totally unrelated areas of maths. This was kind of true in The Music of the Primes too. Seemingly completely different concepts end up being important to each other when you get into the very highest levels of the subject. I like this about maths. It has an internal consistency but unknowability that is pleasing to a certain kind of person. The consistency also relies on things that appear both discovered or invented. The interchanging of these ideas and of these personalities really come through in Singh’s writing. Though again he goes off on a tangent about cryptography which this time I did skim.</p>

			<p>If you’re only going to read one popular maths book this year it should probably be Fermat’s Last Theorem. It’s more accessible and the story is better. Ultimately, we have solved Fermat’s Last Theorem and we haven’t solved the Riemann Hypothesis so it’s intrinsically more satisfying to read the story about the successful effort than the so far futile one. I just enjoyed this more. There’s not a lot more to it.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "FermatsLastTheoremArticle"
	}

</script>


<style>

</style>