<template>
  <div>
    <NuclearWarArticle/>
    <FermatsLastTheoremArticle/>
    <TheMusicOfThePrimesArticle/>
    <PiazzollaArticle/>
    <JamesArticle/>
    <OrbitalArticle/>
    <YitzhakRabinArticle/>
  </div>
</template>

<script>
  import NuclearWarArticle from '@/components/partials/articles/twentyfive/NuclearWarArticle.vue'
  import FermatsLastTheoremArticle from '@/components/partials/articles/twentyfive/FermatsLastTheoremArticle.vue'
  import TheMusicOfThePrimesArticle from '@/components/partials/articles/twentyfive/TheMusicOfThePrimesArticle.vue'
  import PiazzollaArticle from '@/components/partials/articles/twentyfive/PiazzollaArticle.vue'
  import JamesArticle from '@/components/partials/articles/twentyfive/JamesArticle.vue'
  import OrbitalArticle from '@/components/partials/articles/twentyfive/OrbitalArticle.vue'
  import YitzhakRabinArticle from '@/components/partials/articles/twentyfive/YitzhakRabinArticle.vue'



  export default {
  name: 'App',
  components: {
    JamesArticle,
    OrbitalArticle,
    YitzhakRabinArticle,
    PiazzollaArticle,
    TheMusicOfThePrimesArticle,
    FermatsLastTheoremArticle,
    NuclearWarArticle
  }
}
</script>