<template>
    <div>

		<div class="title">
			<p class="main">Astor Piazzolla: A Memoir by Natalio Gorin</p>
			<div class="subheadings">
				<p class="published-date">31st January 2025</p>
				
				
			</div>
		</div>

		<div class="article">
			<p>Astor Piazzolla was the first classical musician I really loved. Obviously most people hear some Bach, or Mozart or Beethoven or something at school and maybe get a taste for it but, when I was starting to learn guitar, it was Piazzolla who really made me think again about what music is. And what classical means. And what the guitar is. I’m going to use the words “classical” and “tango” here in ways that are not quite technically correct but for an easy life just bear with me.</p>

			<p>Natalio Gorin structures this memoir of Piazzolla in a way that reflects the subject’s sudden stroke and illness during its writing. Gorin had intended to finish the book with Piazzolla but when this became impossible, he instead compiled their interviews and what was already written into one book. This means it goes back and forwards a bit and I found myself not quite following some key narrative points. But having said that, that’s not really the point. What the book does brilliantly is get Piazzolla’s personality across. He leaps off the page as a gregarious, slightly obnoxious, self assured guy. He knows that the popularity of nuevo tango is almost entirely down to him and he’s deeply proud understandably.</p>

			<p>After a roundabout life that took him from Argentina to New York and back again and then to Europe, Piazzolla found success as a band leader, composer and arranger of tango music that was highly untraditional. Many of his gigs in Argentina ended with a heckler shouting “Bravo maestro, now play us a tango!” to make the point that Piazzolla’s more esoteric and classically influenced take on Argentina’s national dance was not something the population was keen to see changed.</p>

			<p>But Piazzolla had earned this right in my opinion. He became one of his countries best and most successful musical exports and is regarded as a genius by many people. His odes to the ports of Buenos Aires are some of my favourite bits of music. Libertango was a piece I coveted the ability to play on guitar for about a decade until late last year when I finally mastered it. There is a story that comes up whenever Piazzolla is discussed. Having earned a living as a teenager playing in tango bands in what we might call Venues Of Ill Repute, he received an invitation to study with Nadia Boulanger - the legendary French composition teacher. When he arrived to audition for her and played Bach fugues on his bandoneon (a sort of raspy accordion with way more character), she stopped him. This wasn’t his music and she could tell. “I don’t want to hear Bach”, she supposedly said, “I want to hear Piazzolla”. So Astor played a tango, and the rest was history.</p>

			<p>This memoir is pretty inaccessible if you don’t have a rough knowledge of Piazzolla before you start it, but as I had that I really enjoyed it. I wouldn’t rush out and get it if you don’t already really like his music because it is inescapable that he was, in fact, a bit of a dick. But he still made me think again about what it meant to play music, and the guitar in particular, and I’m grateful to now feel like I know him better - for better or worse.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "PiazzollaArticle"
	}

</script>


<style>

</style>