<template>
    <div>

		<div class="title">
			<p class="main">The Music of the Primes by Marcus du Sautoy</p>
			<div class="subheadings">
				<p class="published-date">13th February 2025</p>
				
				
			</div>
		</div>

		<div class="article">
			<p>Right, first things first - I still don’t understand the Riemann Hypothesis. I don’t think that particular breakthrough was ever really on the cards for me but a little bit of me did wonder if the penny would drop while reading Marcus du Sautoy’s 2003 book The Music of the Primes. This is more of a history of mathematics, or a history of number theory in particular, than it is a story about one problem in particular. And, one weird tangent on cryptography aside (yes, we get it, pure maths is still useful), it’s a highly entertaining read. I’m quite interested in mathematics and was relatively good at it in school. But that was ever such a long time ago and I think even someone who never really liked maths would still find the almost mystery story presented here fun.</p>

			<p>The book falls into 4 main points of structure: A history of mathematics, an explanation of Riemann, his work, and why his hypothesis is important, a second more historical section that focuses on some individual mathematicians (Ramanujan and Rivest, Shamir and Adleman of RSA encryption fame for example), and finally the state of play with the modern investigation into the hypothesis. Ultimately, maths at this level is so complex and abstract that it would take some serious explaining to even professors of mathematics but it should be enough of a primer to understand this: prime numbers are those numbers that can be only be divided by 1 and themselves. 5 is prime, but 15 is not. The Riemann hypothesis tells us something about how often prime numbers appear as we count. It seems instinctively like they are totally random. But as further investigation has been undertaken, there does appear to be a very subtle pattern that can be deduced especially as you get into the realms of truly massive numbers. This is at the heart of what academics call Number Theory - our attempts to understand why numbers work the way they do.</p>

			<p>As what you might call an above average consumer of popular mathematics, I have a reasonably good understanding of the concepts discussed to the extent that it’s possible for someone who hasn’t actually studied them to understand them. I would say this book is written for that kind of audience. I guess that makes sense though as only someone with a decent interest in the history of maths is likely to pick this up and Du Sautoy isn’t being unreasonable to assume that that kind of person will have a rough idea of what he’s talking about. Nevertheless I actually think the best bits here are the sections where Du Sautoy relies heavily on metaphors that are abstract in the extreme. “The Music of the Primes” in itself implies that there is a kind of harmony or consonance to be found in maths and specifically in numbers. This harmony metaphor really comes to fore later in the book when it becomes clear that there may be a connection between the patterns of energy levels of electrons in atoms. But he does this throughout and when it works it really does help to communicate the ideas. The reader is never going to understand the actual maths so a metaphor is helpful.</p>

			<p>There are some sections which read like little additions. The section on cryptography is painful and would benefit from a deeper dive into P vs NP as a question. Similarly the passage on Gödel’s Incompleteness Theorem is interesting but only seems to serve to heighten the jeopardy. P vs NP (whether we can work out if a problem is hard to solve without actually solving it, broadly) and Incompleteness (the fact that you cannot have a system of mathematics that can answer every question) are too broad and interesting to be served by portions of a chapter in a book that already has a lot going on. They don’t help the narrative and they’re insufficiently detailed to be of any real use. I’m not saying skip them, I’m just saying they are lower points than the rest of the book.</p>

			<p>I’d be lying if I said a little bit of didn’t want to actually just find an advanced mathematics course on YouTube and try to get my head around the actual Hypothesis by the end of this. It’s a marker of how interesting it is but also how mind boggling abstract and difficult it is that I felt this way. But really the value here is in the history lesson. How we built up mathematical knowledge; How we developed a system of proof and logic; How wars and history got in the way of advances; How the policies of Napoleon and Hitler even influenced centres of knowledge; The bizarre reality that mathematics seems to genuinely be like sport in that it is a young person’s game. Overall I really enjoyed The Music of the Primes, but I probably enjoyed it for its history more than its maths. That might feel like a bit of an indictment of a book about maths but I actually think it’s an endorsement of the writing.</p>

			<p>Next time: MORE MATHS!</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "TheMusicOfThePrimesArticle"
	}

</script>


<style>

</style>